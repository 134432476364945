<div class="coming_soon_modal2">
    <button (click)="comingSoonClose()" mat-button mat-dialog-close class="close_btn" type="button"
      aria-label="close-circle">
      <img src="./assets/images/close-circle.png" alt="close" />
    </button>
    <div class="coming_modal_content">
      <div class="container">
        <div class="modal_content_outr">
          <div class="text-center">
            <h2>Welcome to the Blue Tees Golf. How can you get started?</h2>
          </div>
          <div class="row g-4">
            <div class="col-md-3 col-sm-6">
              <div class="step_inr">
                <h3>STEP 1</h3>
                <p>
                  Record all your round and shot tracking data in the Blue Tees
                  Game app or RINGER.
                </p>
              </div>
            </div>
            <div class="col-md-3 col-sm-6">
              <div class="step_inr">
                <h3>STEP 2</h3>
                <p>
                  View your round in a whole new way. Improve your game seeing
                  shot trends visually (coming soon in November.)
                </p>
              </div>
            </div>
            <div class="col-md-3 col-sm-6">
              <div class="step_inr">
                <h3>STEP 3</h3>
                <p>
                  View your scorecard and round analytics. Identify areas of
                  strength and opportunities for improvement.
                </p>
              </div>
            </div>
            <div class="col-md-3 col-sm-6">
              <div class="step_inr">
                <h3>STEP 4</h3>
                <p>Share your journey to lower scores with your friends.</p>
              </div>
            </div>
          </div>
          <figure class="cmning_img">
            <img src="./assets/images/cm.png" alt="coming-soon" />
          </figure>
          <p class="cmn_para">
            Here is the experience after you log shot on the Blue Tees devices.
          </p>
        </div>
      </div>
    </div>
    <img src="./assets/images/cmd.png" alt="" class="pic1" />
  </div>
