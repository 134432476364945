import { Component, inject, output } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, EventService } from '@services';
import { Subscription } from 'rxjs';

@Component({
  selector: '[app-onboarding]',
  standalone: true,
  imports: [],
  templateUrl: './onboarding.component.html',
  styleUrl: './onboarding.component.scss'
})
export class OnboardingComponent {
  private api = inject(ApiService);
  private event = inject(EventService);
  private router = inject(Router);

  // responseOnBoard = output<any>();
  comingSoonClose(): void {
    // this.responseOnBoard.emit({ onBoardClose: true })
    const updateAPI: Subscription = this.api.post('/user/update-profile', { display_howto_banner: false }).subscribe({
      next: (response) => {
        if (response.type === 'success') {
          this.event.user.next(response.data);
          this.router.navigate(['/home']);
        }
      },
      error: (error: any) => {

      },
      complete: () => {
        updateAPI && updateAPI.unsubscribe()
      }
    });
  }
}
